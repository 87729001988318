import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
} from "../../util";

let elMap: {
  slantDivs: NodeListOf<HTMLElement>;
  animatedImages: NodeListOf<HTMLElement>;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    slantDivs: document.querySelectorAll(".story-slant"),
    animatedImages: document.querySelectorAll(
      ".story-bg-wrapper img.animate__animated"
    ),
  };

  setupSlantAnimations();
  setupImgAnimations();
}

function setupSlantAnimations() {
  addAnimationClassOncePartiallyVisible(
    elMap.slantDivs[0],
    "animate__slideInLeft"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.slantDivs[1],
    "animate__slideInLeft"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.slantDivs[2],
    "animate__slideInLeft"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.slantDivs[3],
    "animate__slideInLeft"
  );
}

function setupImgAnimations() {
  addAnimationClassOncePartiallyVisible(
    elMap.animatedImages[0],
    "animate__rotateInDownLeft"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.animatedImages[1],
    "animate__rotateInUpRight"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.animatedImages[2],
    "animate__rotateInDownRight"
  );
  addAnimationClassOncePartiallyVisible(
    elMap.animatedImages[3],
    "animate__rotateInUpLeft"
  );
}
